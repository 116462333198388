import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { EAuditLandingPageService } from '../services/eAudit-landing-page.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { catchError, of, tap } from 'rxjs';
import { Token, VERSION } from '@angular/compiler';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-eaudit-landing-page',
  templateUrl: './eaudit-landing-page.component.html',
  styleUrls: ['./eaudit-landing-page.component.scss'],
})
export class EauditLandingPageComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  recaptchaSiteKey = environment.siteKey;
  errorSubmitMsg: string;
  spinner1: string = 'sp3';
  reCAPTCHAVisible: boolean = false;
  reCAPTCHAToken: string = '';
  recaptchaError: boolean = false;
  captchaError: boolean;
  errorMessage:string= '';
  
 


  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    public eAuditService: EAuditLandingPageService,
    private spinner: NgxSpinnerService,
  ) {
    this.errorSubmitMsg = '';
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      erNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('[0-9]{7}'),
          Validators.minLength(7),
          Validators.maxLength(7),
        ],
      ],
      caseId: ['', [Validators.required, Validators.pattern('[0-9]{16}')]],
    });


    this.generateRecaptchaToken();
  }

  generateRecaptchaToken() {
    this.recaptchaV3Service.execute('importantAction').pipe(
      tap((token) => {
        this.reCAPTCHAToken = token;
        this.recaptchaError = false;
      }),
      catchError((error) => {
        console.error('reCAPTCHA error:', error);
        this.handleRecaptchaError(error);
        return of(null);
      })
    ).subscribe();
   }
   // Handle reCAPTCHA errors
   handleRecaptchaError(error: any) {
    console.error('reCAPTCHA error:', error);
      this.errorSubmitMsg = "The system is currently unavailable. Please try again later.";
       this.recaptchaError = true;
   }
  
 
  allowNumbers(e: any) {
    return (
      e.keyCode === 8 ||
      e.keyCode === 9 ||
      e.keyCode === 13 ||
      e.keyCode === 46 ||
      e.keyCode === 37 ||
      e.keyCode === 39 ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      (e.keyCode >= 48 && e.keyCode <= 90) ||
      (e.keyCode == 65 && e.ctrlKey === true) ||
      (e.keyCode == 67 && e.ctrlKey === true) ||
      (e.keyCode == 88 && e.ctrlKey === true)
    );
  }
  onRightClick(event: { preventDefault: () => void }) {
    event.preventDefault(); //this will disable default action of the context menu
  }

  get f(): { [key: string]: AbstractControl } {
    console.log('test', this.form.controls);
    return this.form.controls;
  }

 

  onSubmit(): void {
    this.errorSubmitMsg = '';
    this.submitted = true;
    this.forceValidation();

    if (this.form.invalid) {
      return;
    }


    this.spinner.show();
    this.eAuditService.validateERCaseID(this.form.value).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res && res?.response && res?.response?.opOutput === 'OPFAILED') {
          console.log('res', res);
          let errorOutput = res.response.respErrors.errors[0].output;
          console.log('error outpir', errorOutput);
          if (errorOutput == 'VALIDATION_FAILED_NO_RECORD') {
            this.errorSubmitMsg =
              'Your login was not accepted. Please verify the ER Number or Case ID.';
          }
          else if (errorOutput == 'VALIDATION_FAILED_ARCHIVED') {
            this.errorSubmitMsg =
              'Validation failed, the information provided is no longer available.';
          } 
          else if (errorOutput == 'VALIDATION_FAILED_EXPIRED') {
            this.errorSubmitMsg =
              'Please verify ER Number and Case ID.';
          } 
          
          else {
            let errorMessage = res.response.respErrors.errors[0].message;
            this.errorSubmitMsg = errorMessage;
          }
         } else if (res && res.response && res.response.opOutput === 'OPSUCCESS') {
            window.localStorage['ilogged'] = 'ilogged';
            window.localStorage['caseid'] = this.form.get('caseId')?.value;
            window.localStorage['erNumber'] = this.form.get('erNumber')?.value;
            this.router.navigate(['docintake/eAuditfileUpload']);
          } else {
            this.spinner.hide();
            this.errorSubmitMsg =
              'The system is currently unavailable.  Please try again later.';
          }
      },
      (err) => {
        console.log('errrr', err);
        this.spinner.hide();
        this.errorSubmitMsg =
          'The system is currently unavailable.  Please try again later.';
      }
    );
  }

  onReset(): void {
    this.form.reset();
  }

  forceValidation(): void {
    for (const formField in this.form.controls) {
      const control = this.form.get(formField);

      control?.markAsDirty();
      control?.markAsTouched();
      control?.updateValueAndValidity();
    }
  }

  get caseId() {
    return this.form.get('caseId')!;
  }
  get erNumber() {
    console.log(this.form.get('erNumber'));
    return this.form.get('erNumber')!;
  }
}
