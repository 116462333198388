import { Component } from '@angular/core';
import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { FileUploadService } from '../services/file-upload.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { EAuditLandingPageService } from '../services/eAudit-landing-page.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, catchError, forkJoin, of, tap } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
 
@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss'],
})
export class FileuploadComponent {
  selectedFiles?: FileList;
  currentFile?: any;
  progress = 0;
  message = '';
  fileInfos: any[] = [];
  uploadedFiles: any[] = [];
  triggeredAPIs:any[] = [];
  uploadedFully:boolean = true;
 
  errorSubmitMsg: string | undefined;
  fileSelected100MB: boolean = false;
  successMessage: string | undefined;
  disableUploadError: boolean = false;
  errorMessages: string[] = [];
  errorOccured = 0;
  errorReoccured = 0;
  reCAPTCHAVisible: boolean = false;
  reCAPTCHAToken: string = '';
  recaptchaError: boolean;
  errorSubmitMsgrecaptcha: string;
  totlaFilesUploaded: any;
 
 
  constructor(
    private uploadService: FileUploadService,
    private router: Router,
    private location: Location,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private eAuditService: EAuditLandingPageService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

ngOnInit(): void {
    this.generateRecaptchaToken();
  }
 
  generateRecaptchaToken() {
    this.recaptchaV3Service.execute('importantAction').pipe(
      tap((token) => {
        this.reCAPTCHAToken = token;
        this.recaptchaError = false;
      }),
      catchError((error) => {
        console.error('reCAPTCHA error:', error);
        this.handleRecaptchaError(error);
        return of(null);
      })
    ).subscribe();
   }
   // Handle reCAPTCHA errors
   handleRecaptchaError(error: any) {
    console.error('reCAPTCHA error:', error);
      this.errorSubmitMsgrecaptcha = "The system is currently unavailable. Please try again later.";
       this.recaptchaError = true;
   
   }
 
 
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
    this.checkAllErrors();
  }
 
  checkAllErrors(): void {
    this.disableUploadError = false;
    this.fileSelected100MB = false;
    this.message = '';
    this.errorSubmitMsg = undefined;
    this.errorMessages = [];
    console.log('filenin', this.fileInfos);
 
    if (!this.selectedFiles) {
      return;
    }
 
    if (this.selectedFiles.length > 10) {
      this.errorMessages.push('File upload limit: Maximum of 10 files');
      this.disableUploadError = true;
    }
    const uploaded = this.fileInfos.length;
    const newUploaded = this.selectedFiles.length + uploaded;
    if (newUploaded > 10) {
      if (!this.errorMessages.includes('File upload limit: Maximum of 10 files')) {
        this.errorMessages.push('File upload limit: Maximum of 10 files');
      }
      this.disableUploadError = true;
    }
 
    const allowedTypes = [
      'png',
      'jpg',
      'jpeg',
      'docx',
      'doc',
      'txt',
      'pdf',
      'xls',
      'xlsx',
      'gif',
      'mp4',
      'mp3',
    ];
 
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      const fileFormat = file.name.split('.').pop()?.toLowerCase();
 
      if (!fileFormat || allowedTypes.indexOf(fileFormat) < 0) {
        if (!this.errorMessages.includes('Please upload a document in one of the accepted file types: png, jpg, jpeg, docx, doc, txt, pdf, xls, xlsx, gif, mp4, mp3.')) {
          this.errorMessages.push(
            'Please upload a document in one of the accepted file types: png, jpg, jpeg, docx, doc, txt, pdf, xls, xlsx, gif, mp4, mp3.'
          );
        }
        
        this.disableUploadError = true;
      }
      if (file.size > 104857600) {
        // 100 MB
        if (!this.errorMessages.includes('Please upload document within the file size limit of 100 MB')) {
          this.errorMessages.push(
            'Please upload document within the file size limit of 100 MB'
          );
        }
        
        this.fileSelected100MB = true;
        this.disableUploadError = true;
      }
 
      for (let j = 0; j < this.fileInfos.length; j++) {
        if (this.fileInfos[j].name === file.name) {
          if (!this.errorMessages.includes('File was already uploaded')) {
            this.errorMessages.push(
              'File was already uploaded'
            );
          }
          this.disableUploadError = true;
        }
      }
    }
 
    if (this.errorMessages.length > 0) {
      this.message = this.errorMessages.join('. ');
      console.log('step 1', this.errorMessages);
      console.log('step 2', this.message);
      this.selectedFiles = null;
    }
  }
  
  async uploadEachFile(): Promise<void> {
    if (!this.selectedFiles || this.disableUploadError) {
      return;
    }
 
    this.fileSelected100MB = false;
    this.message = '';
    this.errorSubmitMsg = undefined;
    if (this.selectedFiles.length > 10) {
      this.selectedFiles = null;
      this.message = 'File upload limit: Maximum of 10 files';
      return;
    }
 
    const uploaded = this.fileInfos.length;
    const newUploaded = this.selectedFiles.length + uploaded;
 
    if (newUploaded > 10) {
      this.message = 'File upload limit: Maximum of 10 files';
      return;
    }

    console.log('final files to upload', this.selectedFiles);


 
    for (let i = 0; i < this.selectedFiles.length; i++) {
      await this.upload(this.selectedFiles.item(i));
    }
    this.checkAllFilesUploaded();
  }
  checkAllFilesUploaded() {
    Promise.all(this.triggeredAPIs).then((res:any) => {
      setTimeout(() => {
        console.log('finnnnn', res);
      }, 3000)
     
    }, (err: any) => {
      setTimeout(() => {
        console.log('finnnnn err', err);
      }, 3000)
    });

  }
 
  async upload(item: File | null): Promise<void> {
    if (!item) {
      return;
    }
 
    this.progress = 0;
    this.currentFile = item;
 
    if (this.fileInfos.some((file) => file.name === this.currentFile.name)) {
      this.message = 'File was already uploaded';
      this.currentFile = undefined;
      return;
    }
 
   
    if (this.currentFile.size > 104857600) {
      this.message =
        'Please upload document within the file size limit of 100 MB';
 
      this.currentFile = undefined;
      this.fileSelected100MB = true;
 
      return;
    }
 
    this.spinner.show();
    this.fileInfos.push(this.currentFile);
    let fileContent = await this.uploadService.toBase64(this.currentFile);
    this.currentFile.fileContent = fileContent; 

    let sourceTriggered = this.uploadService.upload(this.currentFile);
    this.triggeredAPIs.push(sourceTriggered);
 
    sourceTriggered.then(
           (event: any) => {
        //console.log('event', event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);
          //console.log('porgess', this.progress);
          if (this.progress && this.progress > 0) {
            this.spinner.show();
          } else {
           // this.spinner.hide();
          }
         
        } else if (event instanceof HttpResponse) {
          this.uploadedFiles.push(event.body);
          console.log(this.fileInfos);
          this.errorOccured = this.errorOccured + 1;
        }
        if (this.progress === 100) {
          this.spinner.hide();
         }
         if (this.errorOccured === this.fileInfos.length) {
          //  this.fileInfos = [];
          setTimeout(() => {
            this.uploadedFully = false;
            this.spinner.hide();
           }, 2000)
          }

      },
 
      (err: any) => {
        console.log('err', err);
        this.spinner.hide();
        this.progress = 0;
        this.errorSubmitMsg =
       'The system is currently unavailable. Please try again later.';

       console.log('stpe 1', this.fileInfos);
       console.log('step 2', this.currentFile);
       this.errorOccured = this.errorOccured + 1;
       this.errorReoccured = this.errorReoccured + 1;
      // if(this.currentFile) {
        for (let i = 0; i < this.fileInfos.length; i++) {
          if (this.fileInfos[i].name === err.currenObj.fileName) {
            this.fileInfos.splice(i, 1);
             //break;
          }
        }  
      // }
       if (this.errorOccured === this.fileInfos.length) {
        //this.fileInfos = [];
        setTimeout(() => {
          this.uploadedFully = false;
          this.spinner.hide();
         }, 2000)
  
       }

       if (this.errorReoccured === this.fileInfos.length && this.errorReoccured === this.errorOccured) {
        this.fileInfos = [];
       
        }

       
        this.currentFile = undefined;
        this.selectedFiles = null;
        this.fileSelected100MB = true;
      },
    );
  }
 
  confirmation(): void {
    const modalRef = this.modalService.open(ConfirmationBoxComponent);
    (<ConfirmationBoxComponent>modalRef.componentInstance).dataToTakeAsInput =
      'Are you sure you want to submit file(s) to NYS Dept of Labor?';
 
    modalRef.result.then((result) => {
      if (result) {
        this.spinner.show();
        this.uploadService.submitData(this.uploadedFiles).subscribe(
          (res: any) => {
            this.spinner.hide();
 
            if (res?.response?.opOutput === 'OPFAILED') {
              this.errorSubmitMsg = res.response.respErrors.errors[0].message;
            } else if (res?.response?.opOutput === 'OPSUCCESS') {
              window.localStorage['confirmationNumber'] =
                res.response.confimationNumber;
 
              this.router.navigate(['docintake/Confirmation']);
            } else {
              this.errorSubmitMsg =
                'The system is currently unavailable. Please try again later.';
            }
          },
 
          (err) => {
            this.spinner.hide();
            this.errorSubmitMsg =
              'The system is currently unavailable. Please try again later.';
          }
        );
      }
    });
  }
 
  removeFile(file: any): void {
    const modalRef = this.modalService.open(ConfirmationBoxComponent);
 
    (<ConfirmationBoxComponent>(
      modalRef.componentInstance
    )).fileToDelete = `Are you sure you want to delete ${file.name}?`;
 
    modalRef.result.then((result) => {
      if (result) {
        this.uploadService.deleteFile(file);
        this.fileInfos = this.fileInfos.filter((f) => f.name !== file.name);
        this.uploadedFiles = this.uploadedFiles.filter(
          (f) => f.orginalFileName !== file.name
        );
      }
    });
  }
 
  onReset(): void {
    this.router.navigate(['docintake/eAudit']);
  }
}