import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { UserGuard } from './services/userguard.service';
import { FileUploadService } from './services/file-upload.service';
import { EAuditLandingPageService } from './services/eAudit-landing-page.service';
import { EauditLandingPageComponent } from './eAudit-landing-page/eaudit-landing-page.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { AppComponent } from './app.component';
import { ConfirmationBoxComponent } from './confirmation-box/confirmation-box.component';
//checkbox
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
//invisible
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    EauditLandingPageComponent,
    ConfirmationComponent,
    FileuploadComponent,
    ConfirmationBoxComponent
  ],
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    RecaptchaV3Module
  ],
  providers: [UserGuard, FileUploadService, EAuditLandingPageService, 
    {provide: RECAPTCHA_V3_SITE_KEY,useValue: environment.siteKey }],
  bootstrap: [AppComponent],
})
export class AppModule { }
